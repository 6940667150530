import {useContext} from 'react';

import {User} from '@polarsignals/client-grpc-web/polarsignals/user/v1alpha1/user';
import {useGrpcQuery} from '@polarsignals/hooks';

import GrpcContext from 'contexts/GrpcContext';

const useCurrentUser = () => {
  const {userServiceClient} = useContext(GrpcContext);

  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<User | null>({
    key: ['getSelf'],
    queryFn: async () => {
      try {
        const {response} = await userServiceClient.getSelf({});
        return response.user ?? null;
      } catch (err: any) {
        if (err.code === 'UNAUTHENTICATED') {
          // Error code 16 (UNAUTHENTICATED) means no active user in session, so this is not an error.
          return null;
        }
        throw new Error(`${err.message as string}, code: ${err.code as string}`);
      }
    },
  });

  const listSharedProfiles = async () => {
    const {response} = await userServiceClient.listSharedProfiles({});
    return response.sharedProfiles ?? [];
  };

  return {data: {user}, loading: isLoading, error, refetch, mutations: {listSharedProfiles}};
};

export default useCurrentUser;
