import {useState} from 'react';

import {Icon} from '@iconify/react';
import {ConditionalWrapper} from '../';
import cx from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

interface CodeWithCopyProps {
  code: string;
  copyCondition?: boolean;
  showAlert?: () => void;
  downloadAsFile?: () => void;
  enableDownload?: boolean;
  rows?: number;
  disabled?: boolean;
}

const CodeWithCopy = ({
  code,
  copyCondition = true,
  showAlert = () => {},
  downloadAsFile = () => {},
  enableDownload = false,
  disabled = false,
  rows = 4,
}: CodeWithCopyProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  return (
    <div className={cx('relative mt-5', disabled && 'pointer-events-none opacity-30')}>
      <textarea
        rows={rows}
        className="mt-3 block h-fit w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pt-8 pr-20 font-mono text-xs text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        placeholder="Leave a comment..."
        value={code}
        readOnly
      />
      <ConditionalWrapper
        condition={copyCondition}
        wrapper={({children}) => (
          <CopyToClipboard text={code} onCopy={onCopyText}>
            {children}
          </CopyToClipboard>
        )}
      >
        <span
          className={cx(
            'absolute  top-2.5 cursor-pointer',
            enableDownload ? 'right-[50px]' : 'right-[25px]'
          )}
          onClick={() => {
            if (!copyCondition) {
              showAlert();
            }
          }}
        >
          {isCopied ? 'Copied!' : <Icon icon="ic:baseline-content-copy" />}
        </span>
      </ConditionalWrapper>
      {enableDownload ? (
        <span className="absolute right-[25px] top-2.5 cursor-pointer">
          <Icon
            icon="ic:outline-file-download"
            height={15}
            onClick={() => {
              if (!copyCondition) {
                showAlert();
                return;
              }
              downloadAsFile();
            }}
          />
        </span>
      ) : null}
    </div>
  );
};

export default CodeWithCopy;
