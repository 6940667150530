import {Button} from '@parca/components';
import {Spinner} from '@polarsignals/components';
import useCurrentUser from 'hooks/useCurrentUser';
import {useRouter} from 'next/router';
import {ProfileInitials} from './ProfileInitials';

const LoginButton = () => {
  const router = useRouter();
  return (
    <Button
      variant="primary"
      onClick={() => {
        router.push({pathname: '/login', query: {from: router.asPath}});
      }}
    >
      Sign In
    </Button>
  );
};

export const UserAvatarButton = () => {
  const {loading, data} = useCurrentUser();

  if (loading) {
    return (
      <span>
        <Spinner size={25} className="!p-0" />
      </span>
    );
  }

  if (data?.user?.id == null) {
    return <LoginButton />;
  }

  return <ProfileInitials />;
};
