import React from 'react';

import {getInitials} from '@polarsignals/functions';
import cx from 'classnames';

interface Props {
  name: string;
  size?: string;
  badge?: React.ReactNode;
}

export const Avatar = ({name, size = 'w-8', badge}: Props) => {
  const initials = getInitials(name);

  return (
    <div className={cx('relative aspect-square', {[size]: size != null})}>
      <div className="group flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-gray-200 pt-0.5 text-center shadow-inner dark:bg-gray-800">
        <span className="align-middle">{initials}</span>
      </div>
      {badge != null ? <div className="absolute top-[60%] right-[-5%]">{badge}</div> : null}
    </div>
  );
};
