import {Fragment} from 'react';

import {Menu, Transition} from '@headlessui/react';
import {Icon} from '@iconify/react';

import {logoutEndpoint} from '../../../constants';
import LinkWithIcon from './LinkWithIcon';
import useCurrentUser from 'hooks/useCurrentUser';
import {Avatar} from '@polarsignals/components';

const getLinks = () => [
  {
    name: 'Your Profiles',
    href: `/profiles`,
    icon: 'material-symbols:format-list-bulleted',
  },
];

export const ProfileInitials = () => {
  const {
    data: {user},
  } = useCurrentUser();

  const userName = user?.name ?? '';
  const userEmail = user?.email ?? '';

  const logout = () => {
    fetch(logoutEndpoint, {credentials: 'include', redirect: 'manual'})
      .then(() => {
        console.log('Logout Successful');
      })
      .catch(err => {
        console.error('Something went wrong', err);
      })
      .finally(() => {
        window.location.href = '/';
      });
  };

  return (
    <Menu as="div" className="relative inline-block p-1 text-left">
      <div>
        <Menu.Button>
          <Avatar name={userName} />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="min-w-56 absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700">
          <div className="px-4 py-3">
            <p className="text-sm dark:text-white ">{userName}</p>
            <p className="break-words text-sm font-medium dark:text-gray-300">{userEmail}</p>
          </div>
          <div className="px-1 py-1">
            {getLinks().map((link, index) => (
              <Menu.Item key={index}>
                {({active}) => (
                  <LinkWithIcon
                    name={link.name}
                    href={link.href}
                    icon={link.icon}
                    isActive={active}
                  />
                )}
              </Menu.Item>
            ))}
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({active}) => (
                <button
                  className={`${
                    active ? 'bg-indigo-500 text-white' : 'text-red-600'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={logout}
                >
                  <span className="mr-2">
                    <Icon icon="tabler:logout" width={20} height={20} />
                  </span>
                  Sign out
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
