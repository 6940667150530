import cx from 'classnames';

import LogoSymmetric from './logo-symmetric.svg';

const Spinner = ({size = 50, className = ''}: {size?: number; className?: string}) => {
  return (
    <div
      className={cx('flex h-full w-full flex-col items-center justify-center p-4', {
        [className]: !!className,
      })}
    >
      <LogoSymmetric className="motion-safe:animate-spin-with-pulse" width={size} height={size} />
      <p className="block motion-safe:hidden">Loading</p>
    </div>
  );
};

export default Spinner;
