// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/user/v1alpha1/user.proto" (package "polarsignals.user.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * UserOrganizationRole represents the role of a user in an organization.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.UserOrganizationRole
 */
export interface UserOrganizationRole {
    /**
     * The unique identifier of the organization.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
    /**
     * The role of the user in the organization.
     *
     * @generated from protobuf field: string role = 2;
     */
    role: string;
}
/**
 * User represents a user.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.User
 */
export interface User {
    /**
     * The unique identifier of the user.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the user.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * The email address of the user.
     *
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * The company of the user.
     *
     * @generated from protobuf field: string company = 4;
     */
    company: string;
    /**
     * The time when the user was created.
     *
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 5;
     */
    createdAt?: Timestamp;
    /**
     * The time when the user was last updated.
     *
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 6;
     */
    updatedAt?: Timestamp;
    /**
     * the feature flags enabled for the user.
     *
     * @generated from protobuf field: repeated string feature_flags = 7;
     */
    featureFlags: string[];
    /**
     * Auth provider of the user.
     *
     * @generated from protobuf field: string auth_provider = 8;
     */
    authProvider: string;
    /**
     * the product tours completed for the user.
     *
     * @generated from protobuf field: repeated string product_tours = 9;
     */
    productTours: string[];
    /**
     * the user's roles.
     *
     * @generated from protobuf field: repeated polarsignals.user.v1alpha1.UserOrganizationRole roles = 10;
     */
    roles: UserOrganizationRole[];
    /**
     * the user's timezone.
     *
     * @generated from protobuf field: string timezone = 11;
     */
    timezone: string;
}
/**
 * GetSelfRequest is the request for the GetSelf method.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.GetSelfRequest
 */
export interface GetSelfRequest {
}
/**
 * GetSelfResponse is the response for the GetSelf method.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.GetSelfResponse
 */
export interface GetSelfResponse {
    /**
     * The user information.
     *
     * @generated from protobuf field: polarsignals.user.v1alpha1.User user = 1;
     */
    user?: User;
}
/**
 * UpdateSelfRequest is the request for the UpdateSelf method.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.UpdateSelfRequest
 */
export interface UpdateSelfRequest {
    /**
     * The user information to update.
     *
     * @generated from protobuf field: polarsignals.user.v1alpha1.User user = 1;
     */
    user?: User;
}
/**
 * UpdateSelfResponse is the response for the UpdateSelf method.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.UpdateSelfResponse
 */
export interface UpdateSelfResponse {
}
/**
 * @generated from protobuf message polarsignals.user.v1alpha1.ListSharedProfilesRequest
 */
export interface ListSharedProfilesRequest {
}
/**
 * @generated from protobuf message polarsignals.user.v1alpha1.SharedProfile
 */
export interface SharedProfile {
    /**
     * The unique identifier of the profile.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The pprof.me slug of the profile
     *
     * @generated from protobuf field: string slug = 2;
     */
    slug: string;
    /**
     * the description of the profile.
     *
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * The user who created the profile.
     *
     * @generated from protobuf field: string user_id = 4;
     */
    userId: string;
    /**
     * The time when the profile was created.
     *
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 5;
     */
    createdAt?: Timestamp;
    /**
     * The time when the profile was last updated.
     *
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 6;
     */
    updatedAt?: Timestamp;
}
/**
 * @generated from protobuf message polarsignals.user.v1alpha1.ListSharedProfilesResponse
 */
export interface ListSharedProfilesResponse {
    /**
     * @generated from protobuf field: repeated polarsignals.user.v1alpha1.SharedProfile shared_profiles = 1;
     */
    sharedProfiles: SharedProfile[];
}
/**
 * UpdateUserProductToursRequest is the request for the UpdateUserProductTours method.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.UpdateUserProductToursRequest
 */
export interface UpdateUserProductToursRequest {
    /**
     * The ID of the user.
     *
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * The name of the tour.
     *
     * @generated from protobuf field: string tour_name = 2;
     */
    tourName: string;
}
/**
 * UpdateUserProductToursResponse is the response for the UpdateUserProductTours method.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.UpdateUserProductToursResponse
 */
export interface UpdateUserProductToursResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class UserOrganizationRole$Type extends MessageType<UserOrganizationRole> {
    constructor() {
        super("polarsignals.user.v1alpha1.UserOrganizationRole", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserOrganizationRole>): UserOrganizationRole {
        const message = { organizationId: "", role: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserOrganizationRole>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserOrganizationRole): UserOrganizationRole {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                case /* string role */ 2:
                    message.role = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserOrganizationRole, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        /* string role = 2; */
        if (message.role !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.role);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.UserOrganizationRole
 */
export const UserOrganizationRole = new UserOrganizationRole$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("polarsignals.user.v1alpha1.User", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "company", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "feature_flags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "auth_provider", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "product_tours", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "roles", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserOrganizationRole },
            { no: 11, name: "timezone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = { id: "", name: "", email: "", company: "", featureFlags: [], authProvider: "", productTours: [], roles: [], timezone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string company */ 4:
                    message.company = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 5:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 6:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* repeated string feature_flags */ 7:
                    message.featureFlags.push(reader.string());
                    break;
                case /* string auth_provider */ 8:
                    message.authProvider = reader.string();
                    break;
                case /* repeated string product_tours */ 9:
                    message.productTours.push(reader.string());
                    break;
                case /* repeated polarsignals.user.v1alpha1.UserOrganizationRole roles */ 10:
                    message.roles.push(UserOrganizationRole.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string timezone */ 11:
                    message.timezone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string company = 4; */
        if (message.company !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.company);
        /* google.protobuf.Timestamp created_at = 5; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 6; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated string feature_flags = 7; */
        for (let i = 0; i < message.featureFlags.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.featureFlags[i]);
        /* string auth_provider = 8; */
        if (message.authProvider !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.authProvider);
        /* repeated string product_tours = 9; */
        for (let i = 0; i < message.productTours.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.productTours[i]);
        /* repeated polarsignals.user.v1alpha1.UserOrganizationRole roles = 10; */
        for (let i = 0; i < message.roles.length; i++)
            UserOrganizationRole.internalBinaryWrite(message.roles[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string timezone = 11; */
        if (message.timezone !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.timezone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSelfRequest$Type extends MessageType<GetSelfRequest> {
    constructor() {
        super("polarsignals.user.v1alpha1.GetSelfRequest", []);
    }
    create(value?: PartialMessage<GetSelfRequest>): GetSelfRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSelfRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSelfRequest): GetSelfRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetSelfRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.GetSelfRequest
 */
export const GetSelfRequest = new GetSelfRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSelfResponse$Type extends MessageType<GetSelfResponse> {
    constructor() {
        super("polarsignals.user.v1alpha1.GetSelfResponse", [
            { no: 1, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<GetSelfResponse>): GetSelfResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSelfResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSelfResponse): GetSelfResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.user.v1alpha1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSelfResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.user.v1alpha1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.GetSelfResponse
 */
export const GetSelfResponse = new GetSelfResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSelfRequest$Type extends MessageType<UpdateSelfRequest> {
    constructor() {
        super("polarsignals.user.v1alpha1.UpdateSelfRequest", [
            { no: 1, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<UpdateSelfRequest>): UpdateSelfRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSelfRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSelfRequest): UpdateSelfRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.user.v1alpha1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateSelfRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.user.v1alpha1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.UpdateSelfRequest
 */
export const UpdateSelfRequest = new UpdateSelfRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSelfResponse$Type extends MessageType<UpdateSelfResponse> {
    constructor() {
        super("polarsignals.user.v1alpha1.UpdateSelfResponse", []);
    }
    create(value?: PartialMessage<UpdateSelfResponse>): UpdateSelfResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSelfResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSelfResponse): UpdateSelfResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateSelfResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.UpdateSelfResponse
 */
export const UpdateSelfResponse = new UpdateSelfResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSharedProfilesRequest$Type extends MessageType<ListSharedProfilesRequest> {
    constructor() {
        super("polarsignals.user.v1alpha1.ListSharedProfilesRequest", []);
    }
    create(value?: PartialMessage<ListSharedProfilesRequest>): ListSharedProfilesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListSharedProfilesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSharedProfilesRequest): ListSharedProfilesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListSharedProfilesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.ListSharedProfilesRequest
 */
export const ListSharedProfilesRequest = new ListSharedProfilesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SharedProfile$Type extends MessageType<SharedProfile> {
    constructor() {
        super("polarsignals.user.v1alpha1.SharedProfile", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "updated_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<SharedProfile>): SharedProfile {
        const message = { id: "", slug: "", description: "", userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SharedProfile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SharedProfile): SharedProfile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string slug */ 2:
                    message.slug = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string user_id */ 4:
                    message.userId = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 5:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 6:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SharedProfile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string slug = 2; */
        if (message.slug !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.slug);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string user_id = 4; */
        if (message.userId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.userId);
        /* google.protobuf.Timestamp created_at = 5; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 6; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.SharedProfile
 */
export const SharedProfile = new SharedProfile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSharedProfilesResponse$Type extends MessageType<ListSharedProfilesResponse> {
    constructor() {
        super("polarsignals.user.v1alpha1.ListSharedProfilesResponse", [
            { no: 1, name: "shared_profiles", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SharedProfile }
        ]);
    }
    create(value?: PartialMessage<ListSharedProfilesResponse>): ListSharedProfilesResponse {
        const message = { sharedProfiles: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListSharedProfilesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSharedProfilesResponse): ListSharedProfilesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.user.v1alpha1.SharedProfile shared_profiles */ 1:
                    message.sharedProfiles.push(SharedProfile.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSharedProfilesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.user.v1alpha1.SharedProfile shared_profiles = 1; */
        for (let i = 0; i < message.sharedProfiles.length; i++)
            SharedProfile.internalBinaryWrite(message.sharedProfiles[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.ListSharedProfilesResponse
 */
export const ListSharedProfilesResponse = new ListSharedProfilesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserProductToursRequest$Type extends MessageType<UpdateUserProductToursRequest> {
    constructor() {
        super("polarsignals.user.v1alpha1.UpdateUserProductToursRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tour_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateUserProductToursRequest>): UpdateUserProductToursRequest {
        const message = { userId: "", tourName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateUserProductToursRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserProductToursRequest): UpdateUserProductToursRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* string tour_name */ 2:
                    message.tourName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserProductToursRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* string tour_name = 2; */
        if (message.tourName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tourName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.UpdateUserProductToursRequest
 */
export const UpdateUserProductToursRequest = new UpdateUserProductToursRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserProductToursResponse$Type extends MessageType<UpdateUserProductToursResponse> {
    constructor() {
        super("polarsignals.user.v1alpha1.UpdateUserProductToursResponse", []);
    }
    create(value?: PartialMessage<UpdateUserProductToursResponse>): UpdateUserProductToursResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateUserProductToursResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserProductToursResponse): UpdateUserProductToursResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateUserProductToursResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.UpdateUserProductToursResponse
 */
export const UpdateUserProductToursResponse = new UpdateUserProductToursResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.user.v1alpha1.UserService
 */
export const UserService = new ServiceType("polarsignals.user.v1alpha1.UserService", [
    { name: "GetSelf", options: { "google.api.http": { get: "/v1/self" } }, I: GetSelfRequest, O: GetSelfResponse },
    { name: "UpdateSelf", options: { "google.api.http": { patch: "/v1/self", body: "*" } }, I: UpdateSelfRequest, O: UpdateSelfResponse },
    { name: "ListSharedProfiles", options: { "google.api.http": { get: "/v1/self/shared-profiles" } }, I: ListSharedProfilesRequest, O: ListSharedProfilesResponse },
    { name: "UpdateUserProductTours", options: { "google.api.http": { post: "/v1/users/{user_id}/product-tours", body: "*" } }, I: UpdateUserProductToursRequest, O: UpdateUserProductToursResponse }
]);
