import {Icon} from '@iconify/react';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="absolute w-full bg-gray-50 pb-4 dark:bg-gray-900">
      <div className="mr-5 ml-5 flex flex-col items-center rounded-2xl bg-[#edf2f7] p-6 text-gray-500 dark:bg-gray-700 dark:text-white md:flex-row md:p-8">
        <p className="text-center"> © {year} Polar Signals. All rights reserved.</p>
        <div className="mt-3 flex items-center space-x-6 md:mt-0 md:ml-auto">
          <a href="https://discord.gg/knw3u5X9bs" target="_blank" rel="noreferrer">
            <Icon icon="ic:baseline-discord" width={24} height={24} />
          </a>
          <a href="https://twitter.com/PolarSignalsIO" target="_blank" rel="noreferrer">
            <Icon icon="bi:twitter" width={24} height={24} />
          </a>
          <a href="https://github.com/polarsignals" target="_blank" rel="noreferrer">
            <Icon icon="uiw:github" width={24} height={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
