import cx from 'classnames';

interface Props {
  text?: string;
}

export function Divider({text}: Props) {
  const hasText = text != null;
  const lineWidth = hasText ? 'w-[calc(50%_-_20px)]' : 'w-full';
  return (
    <div className="relative my-2">
      <div className={cx('absolute inset-0 flex  items-center', lineWidth)} aria-hidden="true">
        <div className="w-full border-t border-gray-300 dark:border-gray-600" />
      </div>
      {text != null ? (
        <>
          <div className="relative flex justify-center">
            <span className={cx('px-2 text-sm')}>{text}</span>
          </div>
          <div
            className={cx('absolute inset-0 left-[calc(50%_+_20px)] flex  items-center', lineWidth)}
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300 dark:border-gray-600" />
          </div>
        </>
      ) : null}
    </div>
  );
}
