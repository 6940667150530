import React, {useEffect} from 'react';
import {useAppSelector} from 'store/hooks';
import {selectDarkMode} from 'store/slices/uiSlice';

const ThemeProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const darkMode = useAppSelector(selectDarkMode);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  return <main className="text-gray-800 dark:text-gray-100">{children}</main>;
};

export default ThemeProvider;
