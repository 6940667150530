import {MoonIcon, SunIcon} from '@heroicons/react/solid';
import {useAppSelector, useAppDispatch} from '../../store/hooks';
import {selectDarkMode, setDarkMode} from '../../store/slices/uiSlice';

const DarkModeToggle = () => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector(selectDarkMode);

  const Icon = isDarkMode ? MoonIcon : SunIcon;
  return (
    <button
      type="button"
      className="align-center flex cursor-pointer items-center rounded-full p-1"
      onClick={() => {}}
    >
      <Icon
        onClick={() => dispatch(setDarkMode(!isDarkMode))}
        className="h-5 w-5"
        aria-hidden="true"
      />
    </button>
  );
};

export default DarkModeToggle;
