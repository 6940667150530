export const capitalize = (a: string): string =>
  a
    .split(' ')
    .map(p => p[0].toUpperCase() + p.substr(1).toLocaleLowerCase())
    .join(' ');

export const removeEmptyKeysFromObject = <T extends object, R extends T>(obj: T): R => {
  return Object.keys(obj)
    .filter(key => !!obj[key])
    .reduce<R>((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {} as R);
};

export const getInitials = (fullname: string): string => {
  const names = fullname.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const isClient = (): boolean => typeof window === 'object';

export const isOSPrefersColorSchemeDark = (): boolean => {
  if (isClient() && typeof window.matchMedia === 'function') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
  return false;
};
