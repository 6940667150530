import React from 'react';

import {UserServiceClient} from '@polarsignals/client-grpc-web/polarsignals/user/v1alpha1/user.client';
import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';

import {apiEndpoint} from '../../constants';

const GRPC_SERVICE_HOST = `${apiEndpoint}/api`;
const GRPC_TRANSPORT = new GrpcWebFetchTransport({
  baseUrl: GRPC_SERVICE_HOST,
  fetchInit: {credentials: 'include'},
  format: 'binary',
});

interface IGrpcContext {
  userServiceClient: UserServiceClient;
}

const defaultValue: IGrpcContext = {
  userServiceClient: new UserServiceClient(GRPC_TRANSPORT),
};

const GrpcContext = React.createContext<IGrpcContext>(defaultValue);

export const GrpcContextProvider = ({children}: {children: React.ReactNode}) => {
  return <GrpcContext.Provider value={defaultValue}>{children}</GrpcContext.Provider>;
};

export const useGrpcContext = () => {
  const context = React.useContext(GrpcContext);
  if (context === undefined) {
    throw new Error('useGrpcContext must be used within a GrpcContextProvider');
  }
  return context;
};

export default GrpcContext;
