// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/user/v1alpha1/user.proto" (package "polarsignals.user.v1alpha1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { UserService } from "./user";
import type { UpdateUserProductToursResponse } from "./user";
import type { UpdateUserProductToursRequest } from "./user";
import type { ListSharedProfilesResponse } from "./user";
import type { ListSharedProfilesRequest } from "./user";
import type { UpdateSelfResponse } from "./user";
import type { UpdateSelfRequest } from "./user";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetSelfResponse } from "./user";
import type { GetSelfRequest } from "./user";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * UserService provides the ability to manage users.
 *
 * @generated from protobuf service polarsignals.user.v1alpha1.UserService
 */
export interface IUserServiceClient {
    /**
     * GetSelf returns the user information for the authenticated user.
     *
     * @generated from protobuf rpc: GetSelf(polarsignals.user.v1alpha1.GetSelfRequest) returns (polarsignals.user.v1alpha1.GetSelfResponse);
     */
    getSelf(input: GetSelfRequest, options?: RpcOptions): UnaryCall<GetSelfRequest, GetSelfResponse>;
    /**
     * UpdateSelf updates the user information for the authenticated user.
     *
     * @generated from protobuf rpc: UpdateSelf(polarsignals.user.v1alpha1.UpdateSelfRequest) returns (polarsignals.user.v1alpha1.UpdateSelfResponse);
     */
    updateSelf(input: UpdateSelfRequest, options?: RpcOptions): UnaryCall<UpdateSelfRequest, UpdateSelfResponse>;
    /**
     * ListSharedProfiles returns the list of profiles shared by the authenticated user.
     *
     * @generated from protobuf rpc: ListSharedProfiles(polarsignals.user.v1alpha1.ListSharedProfilesRequest) returns (polarsignals.user.v1alpha1.ListSharedProfilesResponse);
     */
    listSharedProfiles(input: ListSharedProfilesRequest, options?: RpcOptions): UnaryCall<ListSharedProfilesRequest, ListSharedProfilesResponse>;
    /**
     * UpdateProductTours updates the product tours completed for the user.
     *
     * @generated from protobuf rpc: UpdateUserProductTours(polarsignals.user.v1alpha1.UpdateUserProductToursRequest) returns (polarsignals.user.v1alpha1.UpdateUserProductToursResponse);
     */
    updateUserProductTours(input: UpdateUserProductToursRequest, options?: RpcOptions): UnaryCall<UpdateUserProductToursRequest, UpdateUserProductToursResponse>;
}
/**
 * UserService provides the ability to manage users.
 *
 * @generated from protobuf service polarsignals.user.v1alpha1.UserService
 */
export class UserServiceClient implements IUserServiceClient, ServiceInfo {
    typeName = UserService.typeName;
    methods = UserService.methods;
    options = UserService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * GetSelf returns the user information for the authenticated user.
     *
     * @generated from protobuf rpc: GetSelf(polarsignals.user.v1alpha1.GetSelfRequest) returns (polarsignals.user.v1alpha1.GetSelfResponse);
     */
    getSelf(input: GetSelfRequest, options?: RpcOptions): UnaryCall<GetSelfRequest, GetSelfResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSelfRequest, GetSelfResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateSelf updates the user information for the authenticated user.
     *
     * @generated from protobuf rpc: UpdateSelf(polarsignals.user.v1alpha1.UpdateSelfRequest) returns (polarsignals.user.v1alpha1.UpdateSelfResponse);
     */
    updateSelf(input: UpdateSelfRequest, options?: RpcOptions): UnaryCall<UpdateSelfRequest, UpdateSelfResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSelfRequest, UpdateSelfResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ListSharedProfiles returns the list of profiles shared by the authenticated user.
     *
     * @generated from protobuf rpc: ListSharedProfiles(polarsignals.user.v1alpha1.ListSharedProfilesRequest) returns (polarsignals.user.v1alpha1.ListSharedProfilesResponse);
     */
    listSharedProfiles(input: ListSharedProfilesRequest, options?: RpcOptions): UnaryCall<ListSharedProfilesRequest, ListSharedProfilesResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListSharedProfilesRequest, ListSharedProfilesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateProductTours updates the product tours completed for the user.
     *
     * @generated from protobuf rpc: UpdateUserProductTours(polarsignals.user.v1alpha1.UpdateUserProductToursRequest) returns (polarsignals.user.v1alpha1.UpdateUserProductToursResponse);
     */
    updateUserProductTours(input: UpdateUserProductToursRequest, options?: RpcOptions): UnaryCall<UpdateUserProductToursRequest, UpdateUserProductToursResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateUserProductToursRequest, UpdateUserProductToursResponse>("unary", this._transport, method, opt, input);
    }
}
