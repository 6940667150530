import {PolarSignals} from '@polarsignals/icons';

import Link from 'next/link';

import DarkModeToggle from './DarkModeToggle';

export const LoginNavbar = () => {
  return (
    <nav className="bg-white dark:bg-gray-800">
      <div className="flex flex-col gap-4 border-b-2 border-solid border-gray-200 p-4 dark:border-gray-700 md:flex-row md:items-center lg:pl-24">
        <div className="flex gap-16">
          <div className="relative pb-5 pr-6 ">
            <Link href="/" className="text-2xl">
              pprof.me
            </Link>
            <a
              className="absolute left-0 bottom-0 flex w-fit items-end gap-1 text-xs text-black dark:text-white"
              href="https://www.polarsignals.com"
              target="_blank"
              rel="noreferrer"
            >
              <span>by</span>
              <PolarSignals height="12" width="auto" className="md:pb-[1px]" />
            </a>
          </div>
        </div>

        <div className="flex items-center space-x-4 text-gray-800 dark:text-gray-100 md:ml-auto">
          <DarkModeToggle />
        </div>
      </div>
    </nav>
  );
};
