import {PolarSignals} from '@polarsignals/icons';

import Link from 'next/link';
import {useRouter} from 'next/router';

import DarkModeToggle from './DarkModeToggle';
import {UserAvatarButton} from 'components/UserAvatarButton';
import {LoginNavbar} from './LoginNavbar';
import useCurrentUser from 'hooks/useCurrentUser';
import {Icon} from '@iconify/react';

const Navbar = () => {
  const router = useRouter();
  const {data} = useCurrentUser();

  if (router.pathname === '/login') {
    return <LoginNavbar />;
  }

  return (
    <nav className="bg-white dark:bg-gray-800">
      <div className="flex flex-col gap-4 border-b-2 border-solid border-gray-200 p-4 dark:border-gray-700 md:flex-row md:items-center lg:pl-24">
        <div className="flex justify-between gap-16">
          <div className="relative pb-5 pr-6 ">
            <Link href="/" className="text-2xl">
              pprof.me
            </Link>
            <a
              className="absolute left-0 bottom-0 flex w-fit items-end gap-1 text-xs text-black dark:text-white"
              href="https://www.polarsignals.com"
              target="_blank"
              rel="noreferrer"
            >
              <span>by</span>
              <PolarSignals height="12" width="auto" className="md:pb-[1px]" />
            </a>
          </div>
          <div className="flex items-center gap-4 text-sm">
            <div className="md:hidden">
              <UserAvatarButton />
            </div>
            <div className="hidden lg:flex">
              <Link href="/" className="flex items-center rounded-xl py-2">
                <div className="flex items-center gap-2 rounded border border-gray-200 px-4 py-2 dark:border-gray-700">
                  Upload
                  <Icon icon="ic:outline-file-upload" />
                </div>
              </Link>
            </div>
            <div className="hidden lg:flex">
              <Link href="/compare" className="flex items-center rounded-xl py-1">
                <div className="flex items-center gap-2 rounded border border-gray-200 px-4 py-2 dark:border-gray-700">
                  Compare
                  <Icon icon="carbon:compare" />
                </div>
              </Link>
            </div>
            {data.user?.id != null && router.pathname !== '/profiles' ? (
              <div className="hidden lg:flex">
                <Link href="/profiles" className="flex items-center rounded-xl py-1">
                  <div className="flex items-center gap-2 rounded border border-gray-200 px-4 py-2 dark:border-gray-700">
                    Your Profiles
                    <Icon icon="material-symbols:format-list-bulleted" />
                  </div>
                </Link>
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex items-center space-x-4 text-sm text-gray-800 dark:text-gray-100 md:ml-auto">
          <a href="https://www.polarsignals.com/about-us/">About</a>

          <a href="https://www.polarsignals.com/blog/">Blog</a>

          <a href="https://github.com/polarsignals/issues/issues/new">Open an Issue</a>
          <DarkModeToggle />
          <div className="hidden md:block">
            <UserAvatarButton />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
