import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import {useRouter} from 'next/router';

const Siteframe: React.FC<{children: React.ReactNode}> = ({children}) => {
  const router = useRouter();

  if (router.pathname.endsWith('embed') || router.pathname.endsWith('og-preview')) {
    return <>{children}</>;
  }

  return (
    <main className="relative h-screen min-h-full overflow-auto bg-gray-50 dark:bg-gray-900">
      <Navbar />
      <div className="min-h-[calc(100vh-70px-100px)] p-4">{children}</div>
      <Footer />
    </main>
  );
};

export default Siteframe;
