import {Analytics} from '@vercel/analytics/react';
import type {AppProps} from 'next/app';
import Head from 'next/head';
import {Toaster} from 'react-hot-toast';
import {Provider} from 'react-redux';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import Siteframe from 'layouts/Siteframe';
import ThemeProvider from 'layouts/ThemeProvider';
import store from 'store';
import {createStore as createParcaStore} from '@parca/store';

import '../styles/globals.css';

const {store: reduxStore} = store();

const {store: parcaStore} = createParcaStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({Component, pageProps}: AppProps) {
  return (
    <Provider store={reduxStore}>
      <Provider store={parcaStore}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <Siteframe>
              <Head>
                <title>pprof.me - Share and visualize .pprof profiles</title>
                <link rel="icon" href="/favicon.svg" />
              </Head>
              <Component {...pageProps} />
              <Toaster position="top-right" />
            </Siteframe>
            <Analytics />
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    </Provider>
  );
}

export default MyApp;
