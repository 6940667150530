import {forwardRef} from 'react';

import {Icon} from '@iconify/react';
import Link from 'next/link';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  name: string;
  icon: string;
  isActive: boolean;
}

const LinkWithIcon = ({href, name, icon, isActive, ...props}: Props, ref: any) => {
  return (
    <Link
      href={href}
      {...props}
      className={`${
        isActive ? 'bg-indigo-500 text-white dark:text-white' : 'text-gray-900 dark:text-gray-300'
      } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-indigo-500 hover:text-white`}
      ref={ref}
    >
      <span className="mr-2">
        <Icon icon={icon} width={20} height={20} />
      </span>
      {name}
    </Link>
  );
};

export default forwardRef(LinkWithIcon);
